import { Routes } from '@angular/router';
import { LayoutsComponent } from './layouts';
import { authGuard } from '@shared/auth';

export const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    component: LayoutsComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/blog' },
      {
        path: 'blog',
        canActivate: [authGuard],
        loadChildren: () => import('./pages/blog/blog.routes').then((m) => m.BLOG_ROUTES),
      },
      {
        path: 'blog/:id',
        canActivate: [authGuard],
        loadChildren: () => import('./pages/blog/blog-edit/blog-edit.routes').then((m) => m.BLOG_EDIT_ROUTES),
      },
      {
        path: 'news',
        canActivate: [authGuard],
        loadChildren: () => import('./pages/news/news.routes').then((m) => m.NEWS_ROUTES),
      },
      {
        path: 'news/:id',
        canActivate: [authGuard],
        loadChildren: () => import('./pages/news/news-edit/news-edit.routes').then((m) => m.NEWS_EDIT_ROUTES),
      },
      {
        path: 'job',
        canActivate: [authGuard],
        loadChildren: () => import('./pages/job/job.routes').then((m) => m.JOB_ROUTES),
      },
      {
        path: 'category',
        canActivate: [authGuard],
        loadChildren: () => import('./pages/category/category.routes').then((m) => m.CATEGORY_ROUTES),
      }
    ],
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.routes').then((m) => m.LOGIN_ROUTES),
  },
  { path: '**', redirectTo: '/blog' },
];
