<div class="layout-wrapper" [ngClass]="containerClass()">
  <div class="layout-sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="layout-main-container">
    <div class="layout-main">
      <router-outlet />
    </div>
  </div>
</div>
