<div class="side-topbar layout-topbar">
  <span class="side-topbar__logo">ee admin</span>
  <button
    class="p-link layout-menu-button layout-topbar-button"
    [class.layout-topbar-button-overlay]="layoutService.config().menuMode === 'overlay'"
    (click)="layoutService.onMenuToggle()"
  >
    <i class="pi" [ngClass]="containerClass()"></i>
  </button>
</div>
