import { Injectable, signal } from '@angular/core';

export interface ILayoutConfig {
  menuMode: 'overlay' | 'static';
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private _config: ILayoutConfig = {
    menuMode: 'static',
  };

  config = signal<ILayoutConfig>(this._config);

  onMenuToggle() {
    if (this.isOverlay()) {
      this.config.update(cfg => ({ ...cfg, menuMode: 'static' }))
      return;
    }
    
    if (this.isStatic()) {
      this.config.update(cfg => ({ ...cfg, menuMode: 'overlay' }))
    }
  }

  isOverlay() {
    return this.config().menuMode === 'overlay';
  }
  isStatic() {
    return this.config().menuMode === 'static';
  }
}
