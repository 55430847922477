import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService } from '@shared/auth';


@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent {
  private readonly _auth = inject(AuthService);

  public model = [
    { label: 'Blog', icon: 'pi pi-fw pi-image', routerLink: ['/blog'] },
    { label: 'News', icon: 'pi pi-fw pi-check-square', routerLink: ['/news'] },
    { label: 'Job posts', icon: 'pi pi-fw pi-briefcase', routerLink: ['/job'] },
    { label: 'Category', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/category'] }
  ]

  public logout() {
    this._auth.logout();
  }
}
