import { HttpInterceptorFn } from '@angular/common/http';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const userToken = localStorage.getItem('adm-token');

  if (userToken) {
    req = req.clone({
      headers: req.headers.set('Token', `${userToken}`),
    });
  }

  return next(req);
};
