import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { Component, computed, inject } from '@angular/core';
import { SidebarComponent } from '@shared/components';
import { LayoutService } from '../layout.service';


@Component({
  selector: 'app-layouts',
  standalone: true,
  imports: [CommonModule, RouterOutlet, SidebarComponent],
  templateUrl: './layouts.component.html',
  styleUrl: './layouts.component.scss'
})
export class LayoutsComponent {
  private _layoutService = inject(LayoutService);

  containerClass = computed(() => {
    return {
      'layout-overlay': this._layoutService.config().menuMode === 'overlay',
      'layout-static': this._layoutService.config().menuMode === 'static',
    };
  })
}
