import { Component, OnDestroy, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService } from '@shared/auth';
import { Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnDestroy {
  private _router = inject(Router);
  private _auth = inject(AuthService);

  private readonly _destroying$ = new Subject<void>();

  constructor() {
    this._auth._isAuthorized.pipe(takeUntil(this._destroying$)).subscribe(({ isAuth, isLogin }) => {
      if (isLogin) {
        this._router.navigate(['/']);
      }

      if (!isAuth) {
        this._router.navigate(['/login']);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
