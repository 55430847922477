import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { LayoutService } from 'src/app/layouts';


@Component({
  selector: 'app-side-topbar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './side-topbar.component.html',
  styleUrl: './side-topbar.component.scss'
})
export class SideTopBarComponent {
  public layoutService = inject(LayoutService);

  containerClass = computed(() => {
    return {
      'pi-arrow-circle-left': this.layoutService.config().menuMode === 'static',
      'pi-arrow-circle-right': this.layoutService.config().menuMode === 'overlay',
    };
  })
}
