<ul class="layout-menu">
  @for (item of model; track $index) {
  <li>
    <a
      [routerLink]="item.routerLink"
      routerLinkActive="active-route"
      tabindex="0"
    >
      <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
      <span class="layout-menuitem-text">{{ item.label }}</span>
    </a>
  </li>
  }
  <li (click)="logout()">
    <a
      routerLinkActive="active-route"
      tabindex="0"
    >
      <i ngClass="pi pi-fw pi-sign-out" class="layout-menuitem-icon"></i>
      <span class="layout-menuitem-text">Logout</span>
    </a>
  </li>
</ul>
